import React from "react"
import Layout from "../components/layout";
import Header from "../components/header"
import {Col, Row} from "reactstrap";

const School = () => {

    return <Layout>
        <Row>
            <Col sm="12" lg="8">
                <h1>Szkolenia </h1>
                <p>
                    Pewien błazen zapragnął nagle prowadzić lepsze życie. Zapragnął posiadać bogactwa, żyć w luksusie i
                    odbywać interesujące podróże. Przede wszystkim chciał jednak być szanowany przez innych. Przez całe
                    życie wszyscy wskazywali na niego i mówili: "Patrz to ten błazen". Pragnął, aby w przyszłości ludzie
                    czuli przed nim respekt.

                    Przedstawił swój problem królowi. Ten zdecydował: "Błaźnie, przez wiele lat sprawiałeś mi radość,
                    dlatego chcę spełnić Twoje życzenie i podaruję ci znaczny majątek".

                    Błazen zaczął natychmiast korzystać z tego, czym został obdarowany. Mieszkał w drogim domui jadał
                    kosztowne potrawy. Odkrył jednak, że ludzie wokół niego tylko pozornie okazywali mu szacunek. W
                    rzeczywistości był dla nich nadal błaznem - tylko że bogatym błaznem. Ponadto w krótkim czasie
                    roztrwonił majątek. Przedstawił więc swój problem mądremu doradcy króla. Ten z uśmiechem potrząsnął
                    tylko głową i wskazał na szklankę i karafkę pełną wina. "Nawet gdybym chciał, nie mógłbym z tej
                    karafki przelać wina do tej szklanki. Jest na to za mała. Tak samo twoja osobowość jest za mała w
                    stosunku do twoich życzeń. Król podarował ci majątek, ale twoja osobowość nie pozwoliła ci go
                    utrzymać".
                </p>
                <p>Bez względu na to, czy jesteś doskonale prosperującym przedsiębiorcą czy może pracujesz na etacie,
                    możesz poprawić swoją kondycję finansową. Potrzeba wyłącznie jednej cechy: otwartości na informację.
                    Cechę tę coraz trudniej utrzymać w natłoku informacji, które do nas docierają. Jednakże prawdziwym
                    jest stwierdzenie, iż: "Człowiek uczący się podbije świat, człowiek nauczony dowie się, jak został
                    przygotowany do życia w świecie, którego już nie ma".</p>
                <p>Zapraszam do poszerzania horyzontów na szkoleniach. Dziś radość daje mi współtworzenie projektów
                    szkoleniowych, które mają na celu integrację ludzi otwartych na rozwój oraz podanie praktycznej
                    wiedzy, którą można zastosować w działaniu.

                    Opis mojego aktualnego projektu szkoleniowego znajdziesz pod adresem:

                    www.szkolenie.lrcenter.pl
                </p>
            </Col>
            <Col sm="12" lg="3">
                <img className="H__img" src="/images/school.webp"/>
            </Col>
        </Row>
    </Layout>
}

export default School;
